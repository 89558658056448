
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import { getTableData } from '@/services/tableService'
import ContactsListsTable from '@/components/pages/contacts/lists/ContactsListsTable.vue'
import type { ContactList } from '@/definitions/contacts/lists/types'
import ContactsListsModalFilter from '@/components/pages/contacts/lists/ContactsListsModalFilter.vue'

export default defineComponent({
  name: 'ContactsListsTableModal',
  components: { ContactsListsModalFilter, ContactsListsTable, TmModal },
  props: {
    title: {
      type: String,
      default: 'Lists',
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<ContactList[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'List name', value: 'list-name' },
      { text: 'Contacts', value: 'contacts' },
      { text: 'Type', value: 'type' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<ContactList[]>(getTableData('contactsLists'))
    const filteredTableItems = computed<ContactList[]>(() => {
      return tableItems.value.filter((item) => item.listName.toLowerCase().includes(search.value.toLowerCase()))
    })
    const addContacts = (func: (num: number) => void, func2: () => void) => {
      func(selected.value.length)
      func2()
    }

    return {
      isEmptyMode,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
      addContacts,
    }
  },
})
